// ! Only add scss variables here; DO NOT include any css rendered styles!!!!!!!!!!!!!

$color-gray-dark: #212121;
$color-gray-medium: #424242;
$color-gray-medium-light: #757575;
$color-gray-light: #e0e0e0;

$color-blue-primary: #5cceff;

$color-service-app: #FF7171;
$color-service-web: #40FF75;
$color-service-proto: #36C1FF;
$color-service-cloud: #FF6E41;
$color-service-marketing: #9357FF;
$color-service-other: #e0e0e0;


@import "../stylesheets/variables.scss";

.widget {
    box-sizing: border-box;
    //padding:10px;
    font-family:Arial, sans-serif;
    font-size:1em;
}

/** TODO: Remove this class once you have figured out the sizing of your widget */
.testing-layout-hint {
    background:black;
    //border:rgb(209, 85, 105) solid 4px;
    //color:rgb(206, 110, 126);
}

// Safari has special padding it applies to lists that no one else does >:(
ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

#get-quote .section-content{
    max-width: 1200px;
    padding:0;
}



#get-quote .submitting-progress {
    text-align: center;
    font-size: 24px;

    .submit{
            align-self: center;
            width: 220px;
            height: 50px;
            min-width: 120px;
            font-size: 18px;
            border: none;
            border-radius: 10rem;
            outline: none;
            font-weight: bolder;
            color: white;

            background: linear-gradient(to right, #51D9DA,#49D167);


            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background: #727272;
                color: #989797;
                pointer-events:none;
            }
        }
}

#get-quote form {
    margin: 0;
    max-width: 1000px;
    display:flex;
    flex-direction: column;

    .row{
        display:flex;
        justify-content: space-between;

    }

    .full-row{
        width: 100%;
    }

    .submit-btn-wrapper{
        width: 220px;
        height: 50px;
        margin:auto;
        margin-top:2rem;


        .submit{
            align-self: center;
            width: 220px;
            height: 50px;
            min-width: 120px;
            font-size: 18px;
            border: none;
            border-radius: 10rem;
            outline: none;
            font-weight: bolder;
            color: white;

            //background: linear-gradient(to right, #FFD180, #FF9E80);
            //background: linear-gradient(to right, #51D9DA,#4990D1);
            background: linear-gradient(to right, #51D9DA,#49D167);
            //background: #49D167;
            //background: #4990D1;
            //background: linear-gradient(to right, #51D9DA,#4990D1);
            //background: linear-gradient(to right, #51D9DA,#2F4858)e
            //background: linear-gradient(to right, #2F4858, #51D9DA);

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                background: #727272;
                color: #989797;
                pointer-events:none;
            }
        }
    }


}



#get-quote form .row fieldset{
    display:flex;
    flex-direction: column;
    display:inline-block;
    border:none;
    width:48%;
    box-sizing:border-box;
    margin:10px;
    margin-bottom:0;
    position: relative;

    .warning,.chars-left,.not-enough-chars{
        text-align: end;
        padding-top:5px;
        padding-right: 15px;
        //color: #ec7575d5;
        color: #D1499B;
        margin: 0;
    }

    .warning{
        right:0;
        position: absolute;
    }

    .chars-left{
        color:#51D9DA;
    }

    .bold{
        font-weight: bold;
    }

    label{
        color:white;
        display:block;
        margin-bottom:8px;
        padding-left:5px;
        font-weight:bold;
        font-size:18px;
    }

    input, textarea, select{
        width:100%;
        padding:15px;
        border-radius:30px;
        //background:#424242;
        background: #2F4858;
        appearance:none;
        -webkit-appearance:none;
        border:solid 1px #727272;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
        font-size:18px;
        color:white;
        box-sizing:border-box;
        transition: all .25s ease-in-out;


        &:focus {
            box-shadow: inset 0px 3px 6px rgba(0, 0, 0, .25);
            outline: none;
        }

        &::placeholder{
        }

    }

    .invalid{
        //border: solid 2px #ec7575d5 !important;
        transition: .2s;
        transition-timing-function: ease;
        border: solid 2px #D1499B !important;

    }

    textarea {
        resize: none;
        min-height:150px;
        width: 100%;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
      }
}

#get-quote form .row  .full-width{
    display:flex;
    flex-direction: column;
    width: 100%;
}

#get-quote form .margin-top{
    margin-top: 2rem;
}

#get-quote form .over-eighteen{
    padding-left:1rem;
    padding-left:5px;
    font-weight:bold;
    font-size:18px;
    position:relative;
}

#get-quote form .over-eighteen fieldset{
    border: none;
    display:flex;
    flex-direction: column;
    color: white;

    .checkbox-wrapper{
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    .checkbox{
        height: 20px;
        width: 20px;
        padding: 2rem;
    }

    input:hover{
        cursor: pointer;
    }

    label:hover{
        cursor: pointer;
    }

    .warning{
        padding-left:2px;
        font-weight: 400;
        font-size: 16px;
        text-align: start;
        padding-top:5px;
        color: #D1499B;
        margin: 0;
    }

}


.v-enter-active,
.v-leave-active{
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 963px) {
    #get-quote form .row{
       input, textarea{
            font-size: 16px;

            &::placeholder{
                font-size: 16px;
            }
       }
    }
}

@media only screen and (max-width: 636px){
    #get-quote form .row{
        display:flex;
        flex-direction: column;
        width: 100%;

        input,fieldset{
            width:100%;
            margin:0;
        }
    }
}
